@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@layer base {
  @font-face {
    font-family: 'Minion Pro';
    src: url('./assets/fonts/minion-pro/MinionPro-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

   @font-face {
    font-family: 'Araboto';
    src: url('./assets/fonts/Araboto-Normal-400.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gully';
    src: url('./assets/fonts/gully/Gully-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  html {
    font-family: 'Inter', 'Work Sans', sans-serif !important;
  }

}

.inter {
  font-family: 'Inter', 'Work Sans', sans-serif !important;
}

ul {
  list-style: initial;
  /* Use initial to restore the default list style */
}

.dashed-border {
  background-image: repeating-linear-gradient(90deg, #000000, #000000 25px, transparent 25px, transparent 29px);
  background-repeat: repeat-x;
  background-size: 100% 2px;
}

.dashed-border-top {
  background-position: top;
}

.dashed-border-bottom {
  background-position: bottom;
}

@media (max-width: 1440px) {
  .dashed-border {
    background-image: repeating-linear-gradient(90deg, #000000, #000000 20px, transparent 20px, transparent 24px);
  }
}
@media (max-width: 1140px) {
  .dashed-border {
    background-image: repeating-linear-gradient(90deg, #000000, #000000 15px, transparent 15px, transparent 18px);
  }
}
@media (max-width: 480px) {
  .dashed-border {
    background-image: repeating-linear-gradient(90deg, #000000, #000000 10px, transparent 10px, transparent 12px);
  }
}

body {
  box-sizing: border-box;
  background-color: #FAFBFF;
  line-height: 1;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.my-input::placeholder {
  /* padding: 13px 23px; */
  color: #646464;
  font-size: 16px;
  font-weight: 500;
}

.my-input-danger::placeholder {
  color: #D00000;
  font-size: 16px;
}

input:-webkit-autofill {
  background-color: #f5f9ff !important;
  /* Set your background */
  color: #202020 !important;
}

.blinking-text {
  animation: blink-animation 1.5s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}


.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #5057ff 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #5057ff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}

.redirect-loading {
  width: calc(80px / cos(45deg));
  height: 14px;
  --c: #000 0 15px, #0000 0 20px;
  background:
    repeating-linear-gradient(135deg, var(--c)) left top,
    repeating-linear-gradient(45deg, var(--c)) left bottom;
  background-size: 200% 50%;
  background-repeat: no-repeat;
  animation: l4 2s infinite linear;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn)
  }
}

@keyframes l4 {
  100% {
    background-position: top right, bottom right
  }
}

.spinner {
  color: #272727;
  font-size: 8px;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
  margin-top: 100px;
}

/* The container */
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.filterCheckmark {
  position: absolute;
  top: -2px !important;
  left: 0;
  height: 15px !important;
  width: 15px !important;
  background-color: #fff;
  border-radius: 3px !important;
  border: 2px solid #200000 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;

}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .filterCheckmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #fff;
  border:1px solid black
}

/* Create the checkmark/indicator (hidden when not checked) */
.filterCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .filterCheckmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .filterCheckmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@keyframes mulShdSpin {

  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em,
      2em -2em 0 0em, 3em 0 0 -1em,
      2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 0;
  }

  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em,
      3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  25% {
    box-shadow: 0 -3em 0 -0.5em,
      2em -2em 0 0, 3em 0 0 0.2em,
      2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
      3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
      3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
      3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }

  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em,
      3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em,
      3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.scroll4::-webkit-scrollbar {
  width: 8px;
}

.scroll4::-webkit-scrollbar-thumb {
  background: #7A7A7A;
  border-radius: 20px;
}

.scroll4::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.btn-hover-style {
  transition: outline 0.3s ease;
}

.btn-hover-style:hover {
  outline: 2px solid black;
  /* Adjust the outline color as needed */
  outline-offset: -2px;
  font-weight: bold;
}

.role {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.role input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.break-checkbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.break-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.break-checkbox .checkbox-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  background-color: #fff;
  border: 1.5px solid #327AEC;
}

.break-checkbox input:checked:hover+.checkbox-icon {
  background-color: #327AEC;
}

.break-checkbox input:checked+.checkbox-icon {
  background-color: #FFFF;
}

.break-checkbox input:checked:hover+.checkbox-icon:after {
  border-color: #FFF;
}

.break-checkbox .checkbox-icon:after {
  content: "";
  position: absolute;
  display: none;
}

.break-checkbox input:checked+.checkbox-icon:after {
  display: block;
}

.break-checkbox .checkbox-icon:after {
  left: 12px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid #327AEC;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.break-checkbox .checkbox-icon:hover {
    background-color: #327AEC;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #E1E1E1;
  margin-top: -5px;
}

/* On mouse-over, add a grey background color */
.role:hover input~.checkmark {
  background-color: #327AEC;
}

/* When the radio button is checked, add a blue background */
.role input:checked~.checkmark {
  background-color: #fff;
  border: 1px solid #E1E1E1;
}

.role:hover input:checked~.checkmark {
  background-color: #327AEC;
  border: 1px solid #E1E1E1;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.role input:checked~.checkmark:after {
  display: block;
  background-color: #327AEC;
  width: 14px;
  height: 14px;


}

.role:hover input:checked~.checkmark:after {
  display: block;
  background-color: #fff;
  width: 14px;
  height: 14px;


}

/* Style the indicator (dot/circle) */
.role .checkmark:after {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.highlight-cursor{
  cursor: url('./assets/Images/cursor-text.svg'), auto;
}